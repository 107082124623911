import React, { Fragment, useState } from "react";
import { Container, Nav, NavDropdown, NavItem, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { sidebar_menu, brand_name } from "../themeConfig";
import logo from "../assets/images/logo.svg";
import {
  IconBook,
  IconBuilding,
  IconHome,
  IconMenu2,
  IconPoint,
  IconSettings,
  IconUsers,
  IconX,
} from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../hooks/useIsMobile";
import HeaderDropdown from "./HeaderDropdown";
import { ROLES } from "../common/constants";
import { addsidebarCounter } from "../store/slices/sideBar/sidebarCounterSlice";
import { getEntityCount } from "../api/CommonApi";
import { useEffect } from "react";
import { RIconDashboardFill, RIconExamFill, RIconSetupFill } from "./Icons";

const Sidebar = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.rrb.authUserReducer);
  const location = useLocation();
  const currentUrl = location.pathname;
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState({
    show: false,
    place: "",
  });

  const fetchEntityCount = async () => {
    const { data } = await getEntityCount();
    const { centerCount, divisionCount, postCount, rrbCount, userCount } = data;
    dispatch(
      addsidebarCounter({
        rrbs: rrbCount,
        posts: postCount,
        users: userCount,
        divisions: divisionCount,
        centers: centerCount,
        exams: 0,
      })
    );
  };

  const sidebarCounterSelector = useSelector(
    (state) => state.rrb.sidebarCounterReducer
  );
  useEffect(() => {
    fetchEntityCount();
  }, [sidebarCounterSelector.shouldUpdate]);
  const handleMenuItemClick = () => {
    setIsExpanded(false); // Collapse the menu when a menu item is clicked
  };
  const handleToggle = (isOpen, meta, item) => {
    const hasItems = currentUrl.includes(item);
    setIsOpen({
      show: hasItems ? true : isOpen,
      place: item,
    });
  };

  const handleSelect = (item) => {
    setTimeout(() => {
      setIsOpen({
        show: true,
        place: item,
      });
    }, 0);
  };

  // Define menus for different roles
  const superAdminMenuItems = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <RIconDashboardFill />,
    },
    {
      name: "Setup",
      icon: <RIconSetupFill />,
      submenu: [
        {
          name: "Recruitment Boards",
          icon: <IconPoint size={10} stroke={8} />,
          to: "/recruitment_boards",
          count: sidebarCounterSelector.rrbs,
        },
        {
          name: "Users",
          to: "/users",
          icon: <IconPoint size={10} stroke={8} />,
          count: sidebarCounterSelector.users,
        },
        {
          name: "Posts",
          icon: <IconPoint size={10} stroke={8} />,
          to: "/posts",
          count: sidebarCounterSelector.posts,
        },
      ],
    },
  ];

  const adminMenuItems = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <RIconDashboardFill />,
    },
    {
      name: "Exams",
      to: "/exams",
      icon: <RIconExamFill />,
      count: sidebarCounterSelector.exams,
    },
    {
      name: "Setup",
      icon: <RIconSetupFill />,
      submenu: [
        {
          name: "Divisions",
          to: "/divisions",
          icon: <IconPoint size={10} stroke={8} />,
          count: sidebarCounterSelector.divisions,
        },
        {
          name: "Centers",
          icon: <IconPoint size={10} stroke={8} />,
          to: "/centers",
          count: sidebarCounterSelector.centers,
        },
        {
          name: "Users",
          to: "/users",
          icon: <IconPoint size={10} stroke={8} />,
          count: sidebarCounterSelector.users,
        },
      ],
    },
  ];

  const regionCoordinatorMenuItems = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <RIconDashboardFill />,
    },
    // {
    //   name: "Centers",
    //   to: "/centers",
    //   icon: <IconBuilding size={20} />,
    // },
  ];

  const centerCoordinatorMenuItems = [
    {
      name: "Exams",
      to: "/exams",
      icon: <RIconExamFill />,
    },
    // {
    //   name: "Completed Exams",
    //   to: "/completed-exams",
    //   icon: <IconBook size={20} />,
    // },
    // {
    //   name: "Reports",
    //   to: "/reports",
    //   icon: <IconBook size={20} />,
    // },
  ];

  const getMenuItems = () => {
    switch (authSelector?.role.slug) {
      case "super-admin":
        return superAdminMenuItems;
      case "admin":
        return adminMenuItems;
      case "region-coordinator":
        return regionCoordinatorMenuItems;
      case "center-coordinator":
        return centerCoordinatorMenuItems;
      default:
        return [];
    }
  };
  useEffect(() => {
    const submenuPaths = ["/divisions", "/centers", "/users"]; // Define submenu paths
    const currentPath = location.pathname; // Access current path from useLocation

    // Check if the current path matches any submenu path
    if (submenuPaths.includes(currentPath)) {
      setIsOpen({
        show: true,
        place: "Setup", // Logical parent menu
      });
    } else {
      setIsOpen({
        show: false,
        place: "",
      });
    }
  }, [location.pathname]);
  const SubMenu = ({ item }) => {
    return (
      <NavDropdown
        title={
          <Fragment>
            <span className="nav-link-icon d-md-none d-lg-inline-block">
              {item.icon}
            </span>
            <span className="nav-link-title">{item.name}</span>
          </Fragment>
        }
        id={`topnav-dropdown-${item.name}`}
        className={`${isOpen.show ? "drop-down-item-select-menu d-block" : ""}`}
        show={isOpen.show}
        onToggle={(...args) => handleToggle(...args, item.to)}
        onSelect={() => handleSelect(item.to)}
      >
        <div className="dropdown-menu-columns">
          <div className="dropdown-menu-column">
            {item.submenu.map((subItem) => (
              <NavDropdown.Item
                key={`submenu-${subItem.name}`}
                as={NavLink}
                to={subItem.to}
              >
                {!isMobile && (
                  <span className="nav-link-icon">{subItem.icon}</span>
                )}
                {/* <span className="nav-link-title">{subItem.name}</span> */}
                <span className="nav-link-title">
                  {subItem.name}{" "}
                  {subItem.count !== undefined &&
                    subItem.count !== 0 &&
                    `(${subItem.count})`}
                </span>
              </NavDropdown.Item>
            ))}
          </div>
        </div>
      </NavDropdown>
    );
  };

  const renderNav = () => (
    <Nav as="ul" className=" border-top">
      {getMenuItems().map((item) => (
        <NavItem as="li" key={`topnav-${item.name}`}>
          {item.submenu ? (
            <SubMenu item={item} />
          ) : (
            <Nav.Link as={NavLink} to={item.to} onClick={handleMenuItemClick}>
              {!isMobile && <span className="nav-link-icon">{item.icon}</span>}
              <span className="nav-link-title">{item.name}</span>
            </Nav.Link>
          )}
        </NavItem>
      ))}
    </Nav>
  );

  return (
    <Navbar
      as={"aside"}
      collapseOnSelect
      expand="lg"
      className=" navbar-vertical sticky-top overflow-hidden-"
      expanded={isExpanded} // Control expanded state
      onToggle={(expanded) => setIsExpanded(expanded)} // Update state on toggle
    >
      <Container fluid>
        <Navbar.Brand as={"h1"} className="justify-content-md-start ps-md-3">
          <Link to={"/"}>
            <div className="d-flex align-items-center">
              <img src={logo} className="navbar-brand-image" alt="Logo" />
              <div className="ms-2">
                <div
                  className={`${
                    !isMobile ? "fs-4" : "fs-18"
                  } my-1 text-primary fw-medium`}
                >
                  Railway Recruitment Board
                </div>

                {!isMobile && (
                  <div>
                    {authSelector.role.slug == "super-admin" ? (
                      <div className="fs-5 fw-normal">Ministry of Railways</div>
                    ) : (
                      <div className="fs-5 fw-normal">
                        {authSelector.rrb?.city}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Link>
        </Navbar.Brand>
        {/* <Navbar.Toggle className="ms-auto me-2" /> */}
        <Navbar.Toggle
          className={`ms-auto me-2 ${
            authSelector.role.slug === ROLES.CENTER_COORDINATOR ? "d-none" : ""
          }`}
        >
          {isExpanded ? (
            <IconX size={24} /> // X icon when expanded
          ) : (
            <IconMenu2 size={24} /> // Menu icon when collapsed
          )}
        </Navbar.Toggle>
        <Nav as={"div"} className="flex-row d-lg-none">
          <HeaderDropdown isMobile />
        </Nav>
        <Navbar.Collapse
          className={`${
            authSelector.role.slug === ROLES.CENTER_COORDINATOR ? "d-none" : ""
          }`}
        >
          {renderNav()}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
