import React, { Fragment, useEffect, useMemo, useState } from "react";
import { PageBody } from "../../components/SiteWrapper";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { handleApiError } from "../../common/errorHandler";
import * as SubmissionsAPI from "../../api/SubmissionsAPI";
import Loader from "../../components/Loader";
import CollapsibleCard, { ExamDetails } from "../../components/Exam/reports";
import Q_DATA from "../../api/JSON/questions.json";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import Questions from "./Questions";
import { toast } from "react-toastify";
import { DEFAULT_CONFIRM_OFFCANVAS } from "../../common/constants";
import { ConfirmOffcanvas } from "../../components/CustomModal/ConfirmOffcanvas";
import { noValidationSchema, reportSchema } from "./reportSchema";
import ShiftQuestions from "./Questions/ShiftQuestions";
import moment from "moment";
import QuestionsProgress from "./QuestionsProgress";
import { IconChevronLeft } from "@tabler/icons-react";
import AnimatedComponent from "../../components/Animation/AnimatedComponent";

const shift1QuestionsId = [2, 3, 6, 7, 8, 9];
const shift2QuestionsId = [11, 12, 15, 16, 17, 18];
const shift3QuestionsId = [20, 21, 24, 25, 26, 27];
const dayQuestionsId = [
  28, 29, 32, 36, 38, 39, 40, 41, 42, 43, 44, 46, 47, 48, 49, 50, 51, 52, 53,
  58, 59, 60, 61, 62, 63, 64, 68, 69,
]; //removed being validate 65,66,67
const incidents = {
  1: [28, 32, 33, 34, 35], // Time
  2: [29, 30, 31, 68], // Center
  3: [36], // CCTV
  4: [45, 46, 47, 48, 49, 50, 51], // RRB
  5: [37, 38, 39, 40, 41, 42, 43, 44], // ECA
  6: [52, 58, 59, 60, 61, 62, 65, 66, 67], // Documents
  7: [63, 64], // Police
  8: [53, 54, 55, 56], // Malpractice
};
const Reports = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [examData, setExamData] = useState();
  const [shiftData, setShiftData] = useState([]);
  const [dayQuestions, setDayQuestions] = useState([]);
  const [confirmOffcanvas, setConfirmOffcanvas] = useState(
    DEFAULT_CONFIRM_OFFCANVAS
  );
  const [statuses, setStatuses] = useState({
    shift1: "muted",
    shift2: "muted",
    shift3: "muted",
    day: "muted",
  });

  const [schema, setSchema] = React.useState(noValidationSchema);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors: reportError },
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    // defaultValues:{questions: Array(69).fill("")}
  });
  const updateShiftsWithBoolean = (groupedData, number_of_shift) => {
    // Iterate through the grouped data
    return groupedData.map((shift, index) => {
      if (index < number_of_shift) {
        // Add a boolean value if the shift index is within the number of shifts
        return { ...shift, isIncluded: true };
      } else {
        // Leave the shift unchanged if outside the range
        return { ...shift, isIncluded: false };
      }
    });
  };
  const fetchReport = async () => {
    const groupedData = [];
    let currentGroup = null;
    let currentHeading = null;

    setIsLoading(true);
    try {
      const response = await SubmissionsAPI.getOne(id);
      const data = response?.data;
      if (data) {
        const {
          day,
          name,
          post,
          status,
          submitted,
          questions,
          shifts,
          submitted_at,
          number_of_shift,
        } = data;
        setExamData({
          day,
          name,
          post,
          status,
          submitted,
          shifts,
          submitted_at,
          number_of_shift,
        });
        // Add your logic here
        questions
          .filter((item) => item.type === "shift") // Only consider type: "shift"
          .forEach((item) => {
            if (item.cat && item.ans === 0) {
              // Start a new group if `cat` is non-empty and `ans: 0`
              currentGroup = groupedData.find(
                (group) => group.name === item.cat
              );
              if (!currentGroup) {
                currentGroup = { name: item.cat, heading: [] };
                groupedData.push(currentGroup);
              }
              // Create a new heading object
              currentHeading = { ...item, questions: [] };
              currentGroup.heading.push(currentHeading);
            } else if (item.ans === 0) {
              // For blank `cat`, add a new heading in the current group
              currentHeading = { ...item, questions: [] };
              if (currentGroup) currentGroup.heading.push(currentHeading);
            } else if (currentHeading && item.ans === 1) {
              // Add to the `questions` array of the current heading
              currentHeading.questions.push(item);
            }
          });
        const dayGroupData = questions.filter((item) => item.type === "day");
        const refineShiftData = updateShiftsWithBoolean(
          groupedData,
          number_of_shift
        );
        setShiftData(refineShiftData);
        setDayQuestions(dayGroupData);
        updateAnswers(questions);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchReport();
    }
  }, []);
  const calculateStatus = (questionIds, values) => {
    const answers = questionIds.map(
      (id) =>
        values[`questions_${id}`] && values[`questions_${id}`].trim() !== ""
    );

    const allAnswered = answers.every((answered) => answered);
    const anyAnswered = answers.some((answered) => answered);

    if (allAnswered) return "success"; // All questions answered
    if (anyAnswered) return "warning"; // Some questions answered
    return "muted"; // No questions answered
  };
  useEffect(() => {
    const subscription = watch((values) => {
      setStatuses({
        shift1: calculateStatus(shift1QuestionsId, values),
        shift2: calculateStatus(shift2QuestionsId, values),
        shift3: calculateStatus(shift3QuestionsId, values),
        day: calculateStatus(dayQuestionsId, values),
      });
    });

    return () => subscription.unsubscribe(); // Cleanup on unmount
  }, [watch]);

  const getIncidentKeyByQuestionId = (questionId) => {
    for (const [key, questionIds] of Object.entries(incidents)) {
      if (questionIds.includes(questionId)) {
        return parseInt(key, 10); // Return the key as an integer
      }
    }
    return null; // Return null if no matching key is found
  };
  const updateAnswers = (questionsArray) => {
    const NilCheckFields = [36, 52, 58, 59, 60, 61, 62, 68, 69];
    questionsArray.forEach((question) => {
      if (question.ans === 1 && question.answer !== "") {
        // Dynamically set value for questions_${id}

        if (question.id === 28 && question.answer !== "Yes") {
          setValue(`questions_${question.id}`, "No");
          setValue(`ans_${question.id}`, question.answer);
        } else if (
          NilCheckFields.includes(question.id) &&
          question.answer === "Nil"
        ) {
          setValue(`ans_${question.id}`, "Nil");
        } else if (question.id === 38 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 39 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 40 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 41 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 42 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 46 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 47 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 48 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 49 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 50 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 51 && question.answer !== "Present") {
          setValue(`questions_${question.id}`, "Absent");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 63 && question.answer !== "No") {
          setValue(`questions_${question.id}`, "Yes");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 64 && question.answer !== "No") {
          setValue(`questions_${question.id}`, "Yes");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 65 && question.answer !== "Yes") {
          setValue(`questions_${question.id}`, "No");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 66 && question.answer !== "Yes") {
          setValue(`questions_${question.id}`, "No");
          setValue(`ans_${question.id}`, question.answer);
        } else if (question.id === 67 && question.answer !== "Yes") {
          setValue(`questions_${question.id}`, "No");
          setValue(`ans_${question.id}`, question.answer);
        } else {
          setValue(`questions_${question.id}`, question.answer);
        }
      }
    });
  };
  const formSubmit = async (formData, actionType) => {
    formData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !key.startsWith("incident_"))
    );
    if (formData.questions_28 === "No") {
      formData.incident_28 = getIncidentKeyByQuestionId(28);
      formData.questions_28 = formData.ans_28;
    }
    if (formData.questions_29 === "Not Conducted Smoothly") {
      formData.incident_29 = getIncidentKeyByQuestionId(29);
    }
    if (formData.questions_32 === "Yes") {
      formData.incident_32 = getIncidentKeyByQuestionId(32);
    }
    if (formData.questions_36 !== "Nil" && formData.questions_36 !== "") {
      formData.incident_36 = getIncidentKeyByQuestionId(36);
    }

    //Q5
    if (formData.questions_38 === "Absent") {
      formData.incident_38 = getIncidentKeyByQuestionId(38);
      formData.questions_38 = formData.ans_38;
    }
    if (formData.questions_39 === "Absent") {
      formData.incident_39 = getIncidentKeyByQuestionId(39);
      formData.questions_39 = formData.ans_39;
    }
    if (formData.questions_40 === "Absent") {
      formData.incident_40 = getIncidentKeyByQuestionId(40);
      formData.questions_40 = formData.ans_40;
    }
    if (formData.questions_41 === "Absent") {
      formData.incident_41 = getIncidentKeyByQuestionId(41);
      formData.questions_41 = formData.ans_41;
    }
    if (formData.questions_42 === "Absent") {
      formData.incident_42 = getIncidentKeyByQuestionId(42);
      formData.questions_42 = formData.ans_42;
    }
    if (formData.questions_43 === "No") {
      formData.incident_43 = getIncidentKeyByQuestionId(43);
    }
    if (formData.questions_44 === "No") {
      formData.incident_44 = getIncidentKeyByQuestionId(44);
    }
    //Q8
    if (formData.questions_46 === "Absent") {
      formData.incident_46 = getIncidentKeyByQuestionId(46);
      formData.questions_46 = formData.ans_46;
    }
    if (formData.questions_47 === "Absent") {
      formData.incident_47 = getIncidentKeyByQuestionId(47);
      formData.questions_47 = formData.ans_47;
    }
    if (formData.questions_48 === "Absent") {
      formData.incident_48 = getIncidentKeyByQuestionId(48);
      formData.questions_48 = formData.ans_48;
    }
    if (formData.questions_49 === "Absent") {
      formData.incident_49 = getIncidentKeyByQuestionId(49);
      formData.questions_49 = formData.ans_49;
    }
    if (formData.questions_50 === "Absent") {
      formData.incident_50 = getIncidentKeyByQuestionId(50);
      formData.questions_50 = formData.ans_50;
    }
    if (formData.questions_51 === "Absent") {
      formData.incident_51 = getIncidentKeyByQuestionId(51);
      formData.questions_51 = formData.ans_51;
    }
    if (formData.questions_52 !== "Nil" && formData.questions_52 !== "") {
      formData.incident_52 = getIncidentKeyByQuestionId(52);
    }
    if (formData.questions_53 === "Yes") {
      formData.incident_53 = getIncidentKeyByQuestionId(53);
    }
    if (formData.questions_58 !== "Nil" && formData.questions_58 !== "") {
      formData.incident_58 = getIncidentKeyByQuestionId(58);
    }
    if (formData.questions_59 !== "Nil" && formData.questions_59 !== "") {
      formData.incident_59 = getIncidentKeyByQuestionId(59);
    }
    if (formData.questions_60 !== "Nil" && formData.questions_60 !== "") {
      formData.incident_60 = getIncidentKeyByQuestionId(60);
    }
    if (formData.questions_61 !== "Nil" && formData.questions_61 !== "") {
      formData.incident_61 = getIncidentKeyByQuestionId(61);
    }
    if (formData.questions_62 !== "Nil" && formData.questions_62 !== "") {
      formData.incident_62 = getIncidentKeyByQuestionId(62);
    }

    if (formData.questions_63 === "Yes") {
      formData.incident_63 = getIncidentKeyByQuestionId(63);
      formData.questions_63 = formData.ans_63;
    }
    if (formData.questions_64 === "Yes") {
      formData.incident_64 = getIncidentKeyByQuestionId(64);
      formData.questions_64 = formData.ans_64;
    }
    if (formData.questions_65 === "No") {
      formData.incident_65 = getIncidentKeyByQuestionId(65);
      formData.questions_65 = formData.ans_65;
    }
    if (formData.questions_66 === "No") {
      formData.incident_66 = getIncidentKeyByQuestionId(66);
      formData.questions_66 = formData.ans_66;
    }
    if (formData.questions_67 === "No") {
      formData.incident_67 = getIncidentKeyByQuestionId(67);
      formData.questions_67 = formData.ans_67;
    }
    if (formData.questions_68 !== "Nil" && formData.questions_68 !== "") {
      formData.incident_68 = getIncidentKeyByQuestionId(68);
    }
    if (formData.questions_69 !== "Nil" && formData.questions_69 !== "") {
      formData.incident_69 = getIncidentKeyByQuestionId(69);
    }
    const formattedQuestions = Object.entries(formData)
      .filter(([key]) => key.startsWith("questions_")) // Filter keys starting with "questions_"
      .map(([key, value]) => {
        const id = parseInt(key.split("_")[1], 10); // Extract the ID from the key
        const incidentKey = formData[`incident_${id}`] || null; // Check if incident exists in formData
        return {
          id,
          ans: value === null ? "" : value,
          incident: incidentKey,
        };
      });
    const refineFormData = {
      questions: formattedQuestions,
      action: actionType,
    };
    setIsLoading(true);
    try {
      const response = await SubmissionsAPI.update(id, refineFormData);
      // const data = response?.data;
      if (response) {
        toast.success(
          `Data ${actionType === "save" ? "saved" : "submitted"} successfully!`
        );
        if (actionType === "submit") {
          setExamData((preveState) => ({
            ...preveState,
            submitted: true,
            submitted_at: new Date(),
          }));
        }
        navigate("/");
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
      if (confirmOffcanvas.show) {
        setConfirmOffcanvas(DEFAULT_CONFIRM_OFFCANVAS);
      }
    }
  };
  const handleSave = () => {
    setSchema(noValidationSchema);
    setTimeout(() => {
      handleSubmit((formData) => formSubmit(formData, "save"))();
    }, 0);
  };
  const handleFinalSubmit = (row) => {
    setSchema(reportSchema);
    setTimeout(() => {
      handleSubmit(
        (data) => {
          // If no errors, show the confirmation box
          const examDayDate = moment(examData?.day?.date).format("DD/MM/YYYY");
          setConfirmOffcanvas((prevState) => ({
            ...prevState,
            description: `Are you sure you want to submit the report for exam day ${examDayDate}?`,
            extraMessage: getSubmissionMessage(),
            show: true,
          }));
        },
        (errors) => {}
      )();
    }, 0);
  };
  const onConfirmAction = async () => {
    setConfirmOffcanvas((preveState) => ({ ...preveState, isLoading: true }));
    handleSubmit((formData) => formSubmit(formData, "submit"))();
  };

  const getFormattedDateTime = (submittedAt) => {
    if (!submittedAt) return "No submission date available"; // Handle missing date
    return `Report submitted on ${moment(submittedAt).format(
      "DD/MM/YYYY, h:mm A"
    )}`;
  };
  const getSubmissionMessage = () => {
    // Extract only the shift statuses
    const { number_of_shift = 3 } = examData || {};
    const shiftStatuses = {
      shift1: statuses.shift1,
      shift2: statuses.shift2,
      shift3: statuses.shift3,
    };
    // Include only the shifts up to number_of_shift
    // Filter the statuses based on number_of_shift
    const relevantStatuses = Object.keys(shiftStatuses)
      .slice(0, number_of_shift) // Include only up to number_of_shift
      .map((key) => shiftStatuses[key]); // Get the values (statuses)

    const submittedShiftsCount = relevantStatuses.filter(
      (status) => status === "success"
    ).length;
    // Return false if all required shifts are submitted
    if (submittedShiftsCount === number_of_shift) {
      return false;
    }
    // Count the non-muted shifts
    // const submittedShiftsCount = Object.values(shiftStatuses).filter(
    //   (status) => status === "success"
    // ).length;
    // if (submittedShiftsCount === 3) {
    //   return false;
    // }

    // Generate the message dynamically
    const shiftWord = submittedShiftsCount === 1 ? "shift" : "shifts"; // Singular/plural
    return `You're submitting only  ${submittedShiftsCount} ${shiftWord} data!`;
  };

  return (
    <Fragment>
      <h2 className="px-3 py-2 bg-heading fs-2 mb-0 d-flex align-items-center">
        <Link to="/exams" className="h-auto d-flex align-items-center mt-1">
          <IconChevronLeft className="me-2" />
        </Link>
        Report
      </h2>
      <AnimatedComponent
        as={PageBody}
        className={`mt-0 ${examData?.submitted ? "mb-0" : ""}`}
      >
        {isLoading && <Loader />}
        <Container className={`${!examData?.submitted ? "mb-5 pb-3" : ""}`}>
          <p className="text-muted">
            You can SAVE the report multiple times throught the day. Once you're
            done with your day, you can SUBMIT it only once.
          </p>
          <Form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
            {examData && (
              <CollapsibleCard defaultOpen={true} title="Exam Info">
                <ExamDetails examData={examData} />
              </CollapsibleCard>
            )}

            {shiftData && shiftData.length > 0 && (
              <ShiftQuestions
                {...{
                  examData,
                  shiftData,
                  register,
                  watch,
                  setValue,
                  reportError,
                  trigger,
                  statuses,
                }}
              />
            )}
            {dayQuestions && dayQuestions.length > 0 && (
              <CollapsibleCard
                title="Summarized Status for Exam Day (All Shifts)"
                badgeStatus={statuses.day}
                showBadge={true}
              >
                <Questions
                  {...{
                    dayQuestions,
                    register,
                    watch,
                    setValue,
                    reportError,
                    trigger,
                    isSubmitted: examData.submitted,
                  }}
                />
              </CollapsibleCard>
            )}
          </Form>
          {examData?.submitted && (
            <div className="text-secondary mb-3">
              {getFormattedDateTime(examData?.submitted_at)}
            </div>
          )}
        </Container>
      </AnimatedComponent>
      <AnimatedComponent
        as="footer"
        className={`footer footer-light border-top-0 shadow-rrb px-2 py-3 fixed-bottom ${
          !examData?.submitted ? "" : "d-none"
        }`}
      >
        <Container>
          <Row className="g-3">
            <Col xs={12} className="mt-0 mb-1">
              <QuestionsProgress watch={watch} examData={examData} />
            </Col>
            <Col>
              <Button
                variant="success"
                type="button"
                className="w-100"
                onClick={handleSave}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                variant="danger"
                type="button"
                className="w-100"
                onClick={handleFinalSubmit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </AnimatedComponent>
      {confirmOffcanvas.show && (
        <ConfirmOffcanvas
          {...confirmOffcanvas}
          onActionCallback={onConfirmAction}
          onHide={() => {
            setConfirmOffcanvas((preveState) => ({
              ...preveState,
              show: false,
            }));
          }}
        />
      )}
    </Fragment>
  );
};

export default Reports;
