import React, { Fragment, useEffect, useState } from "react";
import QuestionHeading from "./QuestionHeading";
import { RIconDocument } from "../../../components/Icons";
import { Card, Row } from "react-bootstrap";
import { FormField, FormFieldCheck } from "../../../components/Form/FormField";
const OPTIONS = [{ label: "Nil", value: "Nil" }];
const Q_11 = ({
  dayQuestions,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  isSubmitted,
}) => {
  const [bgClass, setBgClass] = useState("");
  const Q1 = dayQuestions.find((item) => item.id === 57);
  const Q2 = dayQuestions.find((item) => item.id === 58);
  const Q3 = dayQuestions.find((item) => item.id === 59);
  const Q4 = dayQuestions.find((item) => item.id === 60);
  const Q5 = dayQuestions.find((item) => item.id === 61);
  const Q6 = dayQuestions.find((item) => item.id === 62);
  // const selectedAnsQ1 = watch(`questions_${Q1.id}`);
  const selectedAnsQ2 = watch(`questions_${Q2.id}`);
  const selectedAnsQ3 = watch(`questions_${Q3.id}`);
  const selectedAnsQ4 = watch(`questions_${Q4.id}`);
  const selectedAnsQ5 = watch(`questions_${Q5.id}`);
  const selectedAnsQ6 = watch(`questions_${Q6.id}`);
  // const selectedCheck1 = watch(`${Q1.id}`);
  const selectedCheck2 = watch(`ans_${Q2.id}`);
  const selectedCheck3 = watch(`ans_${Q3.id}`);
  const selectedCheck4 = watch(`ans_${Q4.id}`);
  const selectedCheck5 = watch(`ans_${Q5.id}`);
  const selectedCheck6 = watch(`ans_${Q6.id}`);
  const selectedAnswers = [
    selectedAnsQ2,
    selectedAnsQ3,
    selectedAnsQ4,
    selectedAnsQ5,
    selectedAnsQ6,
  ];
  useEffect(() => {
    if (selectedAnsQ2 === "Nil") {
      setValue(`questions_${Q2.id}`, "");
    }
    if (selectedCheck2 === "Nil") {
      setValue(`questions_${Q2.id}`, "Nil");
      trigger(`questions_${Q2.id}`);
    }

    if (selectedAnsQ3 === "Nil") {
      setValue(`questions_${Q3.id}`, "");
    }
    if (selectedCheck3 === "Nil") {
      setValue(`questions_${Q3.id}`, "Nil");
      trigger(`questions_${Q3.id}`);
    }

    if (selectedAnsQ4 === "Nil") {
      setValue(`questions_${Q4.id}`, "");
    }
    if (selectedCheck4 === "Nil") {
      setValue(`questions_${Q4.id}`, "Nil");
      trigger(`questions_${Q4.id}`);
    }

    if (selectedAnsQ5 === "Nil") {
      setValue(`questions_${Q5.id}`, "");
    }
    if (selectedCheck5 === "Nil") {
      setValue(`questions_${Q5.id}`, "Nil");
      trigger(`questions_${Q5.id}`);
    }

    if (selectedAnsQ6 === "Nil") {
      setValue(`questions_${Q6.id}`, "");
    }
    if (selectedCheck6 === "Nil") {
      setValue(`questions_${Q6.id}`, "Nil");
      trigger(`questions_${Q6.id}`);
    }
    if (selectedAnswers.every((answer) => answer === "Nil")) {
      setBgClass("success");
    } else if (selectedAnswers.some((answer) => answer !== "Nil" && answer !== "")) {
      setBgClass("danger");
    } else {
      setBgClass("");
    }
  }, [
    selectedAnsQ2,
    selectedAnsQ3,
    selectedAnsQ4,
    selectedAnsQ5,
    selectedAnsQ6,
    selectedCheck2,
    selectedCheck3,
    selectedCheck4,
    selectedCheck5,
    selectedCheck6,
  ]);
  return (
    <Fragment>
      <Card.Body className={`question-heading px-3 bg-${bgClass}-lt text-dark`}>
        <QuestionHeading
          icon={<RIconDocument className={`text-primary text-${bgClass}`} />}
          number="11"
          title={Q1.subcat}
        />
      </Card.Body>
      <Card.Body className="px-3">
        <Row>
          <FormField
            label={Q2.subcat}
            name={`questions_${Q2.id}`}
            register={register}
            size="12"
            className={`mb-2 ${selectedCheck2 ? "text-transparent" : ""}`}
            error={reportError?.[`questions_${Q2.id}`]}
            disabled={isSubmitted || selectedCheck2}
          />
          <FormFieldCheck
            isLabel={false}
            name={`ans_${Q2.id}`}
            register={register}
            options={OPTIONS}
            type="checkbox"
            size="12"
            disabled={isSubmitted}
          />
          <FormField
            label={Q3.subcat}
            name={`questions_${Q3.id}`}
            register={register}
            size="12"
            className={`mb-2 ${selectedCheck3 ? "text-transparent" : ""}`}
            error={reportError?.[`questions_${Q3.id}`]}
            disabled={isSubmitted || selectedCheck3}
          />
          <FormFieldCheck
            isLabel={false}
            name={`ans_${Q3.id}`}
            register={register}
            options={OPTIONS}
            type="checkbox"
            size="12"
            disabled={isSubmitted}
          />
          <FormField
            label={Q4.subcat}
            name={`questions_${Q4.id}`}
            register={register}
            size="12"
            className={`mb-2 ${selectedCheck4 ? "text-transparent" : ""}`}
            error={reportError?.[`questions_${Q4.id}`]}
            disabled={isSubmitted || selectedCheck4}
          />
          <FormFieldCheck
            isLabel={false}
            name={`ans_${Q4.id}`}
            register={register}
            options={OPTIONS}
            type="checkbox"
            size="12"
            disabled={isSubmitted}
          />
          <FormField
            label={Q5.subcat}
            name={`questions_${Q5.id}`}
            register={register}
            size="12"
            className={`mb-2 ${selectedCheck5 ? "text-transparent" : ""}`}
            error={reportError?.[`questions_${Q5.id}`]}
            disabled={isSubmitted || selectedCheck5}
          />
          <FormFieldCheck
            isLabel={false}
            name={`ans_${Q5.id}`}
            register={register}
            options={OPTIONS}
            type="checkbox"
            size="12"
            disabled={isSubmitted}
          />
          <FormField
            label={Q6.subcat}
            name={`questions_${Q6.id}`}
            register={register}
            size="12"
            className={`mb-2 ${selectedCheck6 ? "text-transparent" : ""}`}
            error={reportError?.[`questions_${Q6.id}`]}
            disabled={isSubmitted || selectedCheck6}
          />
          <FormFieldCheck
            isLabel={false}
            name={`ans_${Q6.id}`}
            register={register}
            options={OPTIONS}
            type="checkbox"
            size="12"
            disabled={isSubmitted}
          />
        </Row>
      </Card.Body>
    </Fragment>
  );
};

export default Q_11;
