import React, { Fragment, useState } from "react";
import { PageBody } from "../../components/SiteWrapper";
import { Container } from "react-bootstrap";
import { ExamList } from "../../components/Exam";
const exams = [
  {
    title: "CEN 01/2024", // Exam identifier
    subtitle: "Railway Protection Force Sub Inspector (RPF SI)", // Exam description
    headerBg: "bg-warning-lt", // Dynamic header background color
    rows: [
      {
        id: 1,
        date: "2024-12-07", // Date of the exam
        centerInfo: "8348 | iON Bopal", // Center details
        location: "Ahmedabad, ADI", // Location details
        status: "Completed", // Status of the exam
        statusBg: "success-lt", // Background color for the status badge
      },
      {
        id: 2,
        date: "2024-12-09",
        centerInfo: "5678 | Exam Center 3",
        location: "Delhi, NDLS",
        status: "Completed",
        statusBg: "success-lt",
      },
    ],
  },
];
const CenterCoordinateExamsCompleted = () => {
  const [completedExams, setCompletedExams] = useState(exams);
  return (
    <Fragment>
      <h2 className="px-3 py-2 bg-heading fs-2">Completed Exams</h2>
      <PageBody className="mt-0">
        <Container>
          {/* In Progress Exams */}
          <ExamList
            title=""
            exams={completedExams.map((exam) => ({
              title: exam.title,
              subtitle: exam.subtitle,
              headerBg: "bg-warning-lt",
              rows: exam.rows,
              footer: "View Report",
            }))}
          />
        </Container>
      </PageBody>
    </Fragment>
  );
};

export default CenterCoordinateExamsCompleted;
