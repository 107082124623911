import React, { Fragment, useMemo } from "react";
import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import DateBox from "../General/DateBox";
import { Link } from "react-router-dom";
import moment from "moment";
import { IconCircleCheck, IconCircleCheckFilled } from "@tabler/icons-react";
const bgColor = {
  "In Progress": "warning-lt",
  Completed: "success-lt",
  Upcoming: "purple-lt",
};

export const ExamRow = ({ row }) => {
  const {
    id,
    date,
    examCenters,
    status,
    DayStatus,
    shift1SubmissionStatus,
    shift2SubmissionStatus,
    shift3SubmissionStatus,
    daySubmissionStatus,
    number_of_shift,
    submitted,
    submitted_at
  } = row;
  const isToday = moment(date).isSame(moment(), "day");
  const reportedDate = (submitted_at) => moment(submitted_at, true).isValid() ? moment(submitted_at).format("DD/MM/YYYY, h:mm A") : false;
  console.log("🚀 ~ ExamRow ~ reportedDate:", reportedDate)
  const statusClassMap = useMemo(
    () => ({
      Pending: "default",
      Completed: "success",
      "In Progress": "warning",
    }),
    []
  );

  const getStatusClass = (status) => statusClassMap[status] || "default";
  return (
    <Fragment>
      <Row className="align-items-center">
        <Col className="col-auto">
          <DateBox date={date} />
        </Col>
        <Col>
          {examCenters[0]?.center ? (
            <>
              <h3 className="fw-medium mb-0 fs-18">
                {examCenters[0].center.center_code}
                <span className="mx-2">|</span>
                {examCenters[0].center.center_name}
              </h3>
              <span className="text-secondary fs-3 lh-base">
                {examCenters[0].center.divisionCity?.city},{" "}
                {examCenters[0].center.divisionCity?.division?.name}
              </span>
            </>
          ) : (
            <span className="text-secondary fs-3 lh-base">NA</span>
          )}
        </Col>
        <Col className="col-auto">
          <Badge bg={bgColor[`${DayStatus}`]} className="border">
            {DayStatus}
          </Badge>
        </Col>
      </Row>
      {/* Show "Report" button only if status is "In Progress" */}
      {submitted && (
        <div className="mt-2">
          <IconCircleCheck width={18} className="me-1 text-success" />
          Report submitted on {reportedDate(submitted_at)}
        </div>
      )}
      {DayStatus === "In Progress" && isToday && !submitted && (
        <Fragment>
          <Link className="btn btn-primary w-100 mt-3" to={`/reports/${id}`}>
            Report
          </Link>
          <ul className="list-inline mt-3 d-flex">
            <li className="list-inline-item me-3 d-flex align-items-center">
              <span
                className={` badge badge-empty badge-empty-sm me-1 bg-${getStatusClass(
                  shift1SubmissionStatus
                )}`}
              ></span>{" "}
              Shift 1
            </li>
            {number_of_shift > 1 && (
              <li className="list-inline-item me-3 d-flex align-items-center">
                <span
                  className={`badge badge-empty badge-empty-sm me-1 bg-${getStatusClass(
                    shift2SubmissionStatus
                  )}`}
                ></span>{" "}
                Shift 2
              </li>
            )}
            {number_of_shift > 2 && (
              <li className="list-inline-item me-3 d-flex align-items-center">
                <span
                  className={`badge badge-empty badge-empty-sm me-1 bg-${getStatusClass(
                    shift3SubmissionStatus
                  )}`}
                ></span>{" "}
                Shift 3
              </li>
            )}
            <li className="list-inline-item d-flex align-items-center">
              <span
                className={`badge badge-empty badge-empty-sm me-1 bg-${getStatusClass(
                  daySubmissionStatus
                )}`}
              ></span>{" "}
              Day
            </li>
          </ul>
        </Fragment>
      )}
      {/* {DayStatus === "Completed" && (
        <Link
          to={`/reports/${id}`}
          className="btn btn-default w-100 mt-3 bg-light-rrb text-primary"
        >
          View Report
        </Link>
      )} */}
    </Fragment>
  );
};

// ExamCard Component
export const ExamCard = ({ title, subtitle, headerBg, rows }) => {
  return (
    <Card className="card-sm">
      <Card.Header className={`px-3 py-2 ${headerBg}`}>
        <Card.Title as={"h3"} className="text-dark">
          {title}
          <span className="d-block text-secondary fs-4 fw-normal lh-sm">
            {subtitle}
          </span>
        </Card.Title>
      </Card.Header>
      {rows.map((row, index) => (
        <Card.Body key={index}>
          <ExamRow row={row} />
        </Card.Body>
      ))}
    </Card>
  );
};

// ExamList Component
export const ExamList = ({ title, exams }) => {
  return (
    <Row className="mb-4">
      <Col lg={12}>
        <h2>{title}</h2>
      </Col>
      {exams.map((exam, index) => (
        <Col lg={12} key={index} className="mb-3">
          <ExamCard {...exam} />
        </Col>
      ))}
    </Row>
  );
};
