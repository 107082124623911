import React, { Fragment } from "react";
import Q_01 from "./Q_01";
import Q_02 from "./Q_02";
import Q_03 from "./Q_03";
import Q_04 from "./Q_04";
import Q_05 from "./Q_05";
import Q_06 from "./Q_06";
import Q_07 from "./Q_07";
import Q_08 from "./Q_08";
import Q_09 from "./Q_09";
import Q_10 from "./Q_10";
import Q_12 from "./Q_12";
import Q_13 from "./Q_13";
import Q_14 from "./Q_14";
import Q_15 from "./Q_15";
import Q_16 from "./Q_16";
import Q_17 from "./Q_17";
import Q_18 from "./Q_18";
import Q_11 from "./Q_11";

const Questions = ({ dayQuestions, register, watch, setValue,reportError,trigger,isSubmitted }) => {
  const components = [Q_01,Q_02,Q_03,Q_04,Q_05,Q_06,Q_07,Q_08,Q_09,Q_10,Q_11,Q_12,Q_13,Q_14,Q_15,Q_16,Q_17,Q_18]; // Add other question components here

  return (
    <Fragment>
      {components.map((Component, index) => (
        <Component
          key={index}
          {...{ dayQuestions, register, watch, setValue,reportError,trigger,isSubmitted }}
        />
      ))}
    </Fragment>
  );
};

export default Questions;
