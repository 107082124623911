import React from "react";

const QuestionHeading = ({ icon, number, title }) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
        <div className="q-icon-box bg-white border rounded d-flex justify-content-center align-items-center">{icon}</div>
        <div className="q-number">{number}</div>
      </div>
      <h3 className="fs-18 fw-medium mb-0">{title}</h3>
    </div>
  );
};

export default QuestionHeading;
