import React, { Fragment, useEffect, useState } from "react";
import QuestionHeading from "./QuestionHeading";
import { RIconPC } from "../../../components/Icons";
import { Card, Row } from "react-bootstrap";
import { FormField, FormFieldCheck } from "../../../components/Form/FormField";
const OPTIONS = [{ label: "Nil", value: "Nil" }];
const Q_17 = ({
  dayQuestions,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  isSubmitted,
}) => {
  const [bgClass, setBgClass] = useState("");
  const Q1 = dayQuestions.find((item) => item.id === 68);
  const selectedAns = watch(`questions_${Q1.id}`);
  const selectedCheck1 = watch(`ans_${Q1.id}`);
  useEffect(() => {
    if (selectedAns === "Nil") {
      setValue(`questions_${Q1.id}`, "");
    }
    if (selectedCheck1 === "Nil") {
      setValue(`questions_${Q1.id}`, "Nil");
      trigger(`questions_${Q1.id}`);
    }
    if (selectedAns) {
      setBgClass(selectedAns === "Nil" ? "success" : "danger");
    }
    else{
      setBgClass("")
    }
  }, [selectedAns, selectedCheck1]);
  return (
    <Fragment>
      <Card.Body className={`question-heading px-3 bg-${bgClass}-lt text-dark`}>
        <QuestionHeading icon={<RIconPC className={`text-primary text-${bgClass}`} />} number="17" title={Q1.subcat} />
      </Card.Body>
      <Card.Body className="px-3">
        <Row>
          <FormField
            label={"Brief Details"}
            name={`questions_${Q1.id}`}
            register={register}
            size="12"
            className={`mb-2 ${selectedCheck1 ? "text-transparent" : ""}`}
            as="textarea"
            rows={4}
            error={reportError?.[`questions_${Q1.id}`]}
            disabled={isSubmitted || selectedCheck1}
          />
          <FormFieldCheck
            isLabel={false}
            name={`ans_${Q1.id}`}
            register={register}
            options={OPTIONS}
            type="checkbox"
            size="12"
            disabled={isSubmitted}
          />
        </Row>
      </Card.Body>
    </Fragment>
  );
};

export default Q_17;
