import React, { Fragment, useEffect, useState } from "react";
import QuestionHeading from "./QuestionHeading";
import { RIconUser, RIconWatch } from "../../../components/Icons";
import { Card, Row } from "react-bootstrap";
import { FormFieldCheck } from "../../../components/Form/FormField";

const OPTIONS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
const Q_06 = ({
  dayQuestions,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  isSubmitted
}) => {
  const [bgClass, setBgClass] = useState("");
  const Q1 = dayQuestions.find((item) => item.id === 43);
  const selectedAns = watch(`questions_${Q1.id}`);
  useEffect(() => {
    if(selectedAns){
      setBgClass(selectedAns==="Yes"?"success":"danger");
    }
  }, [selectedAns]);
  return (
    <Fragment>
      <Card.Body className={`question-heading px-3 bg-${bgClass}-lt text-dark`}>
        <QuestionHeading icon={<RIconUser className={`text-primary text-${bgClass}`} />} number="06" title={Q1.subcat} />
      </Card.Body>
      <Card.Body className="px-3 py-2">
        <Row className="row-cards">
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q1.id}`}
            register={register}
            options={OPTIONS}
            size="12"
            error={reportError?.[`questions_${Q1.id}`]}
            disabled={isSubmitted}
          />
        </Row>
      </Card.Body>
    </Fragment>
  );
};

export default Q_06;
