import React, { Fragment, useEffect, useState } from "react";
import QuestionHeading from "./QuestionHeading";
import { RIconUser } from "../../../components/Icons";
import { Card, Col, Row } from "react-bootstrap";
import { FormField, FormFieldCheck } from "../../../components/Form/FormField";
const OPTIONS = [
  { label: "Present", value: "Present" },
  { label: "Absent", value: "Absent" },
];
const Q_05 = ({
  dayQuestions,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  isSubmitted,
}) => {
  const [bgClass, setBgClass] = useState("");
  const Q1 = dayQuestions.find((item) => item.id === 37);
  const Q2 = dayQuestions.find((item) => item.id === 38);
  const Q3 = dayQuestions.find((item) => item.id === 39);
  const Q4 = dayQuestions.find((item) => item.id === 40);
  const Q5 = dayQuestions.find((item) => item.id === 41);
  const Q6 = dayQuestions.find((item) => item.id === 42);

  const selectedAnsQ_2 = watch(`questions_${Q2.id}`);
  const selectedAnsQ_3 = watch(`questions_${Q3.id}`);
  const selectedAnsQ_4 = watch(`questions_${Q4.id}`);
  const selectedAnsQ_5 = watch(`questions_${Q5.id}`);
  const selectedAnsQ_6 = watch(`questions_${Q6.id}`);
  const selectedAnswers = [
    selectedAnsQ_2,
    selectedAnsQ_3,
    selectedAnsQ_4,
    selectedAnsQ_5,
    selectedAnsQ_6,
  ];
  useEffect(() => {
    if (selectedAnsQ_2 === OPTIONS[0].value) {
      setValue(`ans_${Q2.id}`, "");
    }
    if (selectedAnsQ_3 === OPTIONS[0].value) {
      setValue(`ans_${Q3.id}`, "");
    }
    if (selectedAnsQ_4 === OPTIONS[0].value) {
      setValue(`ans_${Q4.id}`, "");
    }
    if (selectedAnsQ_5 === OPTIONS[0].value) {
      setValue(`ans_${Q5.id}`, "");
    }
    if (selectedAnsQ_6 === OPTIONS[0].value) {
      setValue(`ans_${Q6.id}`, "");
    }
    if (selectedAnswers.includes(OPTIONS[1].value)) {
      setBgClass("danger");
    } else if (selectedAnswers.every((answer) => answer === OPTIONS[0].value)) {
      setBgClass("success");
    } else {
      setBgClass("");
    }
  }, [
    selectedAnsQ_2,
    selectedAnsQ_3,
    selectedAnsQ_4,
    selectedAnsQ_5,
    selectedAnsQ_6,
  ]);
  return (
    <Fragment>
      <Card.Body className={`question-heading px-3 bg-${bgClass}-lt text-dark`}>
        <QuestionHeading
          icon={<RIconUser className={`text-primary text-${bgClass}`} />}
          number="05"
          title={Q1.subcat}
        />
      </Card.Body>
      <Card.Body className="px-3 py-2">
        <Row className="mb-2 align-items-center">
          <Col xs={""} className={`fs-3 fw-medium`}>
            {Q2.subcat}
          </Col>
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q2.id}`}
            register={register}
            options={OPTIONS}
            size="auto"
            error={reportError?.[`questions_${Q2.id}`]}
            disabled={isSubmitted}
          />
        </Row>
        {selectedAnsQ_2 === OPTIONS[1].value && (
          <Row className="mb-3">
            <FormField
              label={"How many short?"}
              name={`ans_${Q2.id}`}
              register={register}
              size="12"
              error={reportError?.[`ans_${Q2.id}`]}
              disabled={isSubmitted}
            />
          </Row>
        )}
        <Row className="mb-2 align-items-center">
          <Col xs={""} className="fs-3 fw-medium">
            {Q3.subcat}
          </Col>
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q3.id}`}
            register={register}
            options={OPTIONS}
            size="auto"
            error={reportError?.[`questions_${Q3.id}`]}
            disabled={isSubmitted}
          />
        </Row>
        {selectedAnsQ_3 === OPTIONS[1].value && (
          <Row className="mb-3">
            <FormField
              label={"How many short?"}
              name={`ans_${Q3.id}`}
              register={register}
              size="12"
              error={reportError?.[`ans_${Q3.id}`]}
              disabled={isSubmitted}
            />
          </Row>
        )}
        <Row className="mb-2 align-items-center">
          <Col xs={""} className="fs-3 fw-medium">
            {Q4.subcat}
          </Col>
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q4.id}`}
            register={register}
            options={OPTIONS}
            size="auto"
            error={reportError?.[`questions_${Q4.id}`]}
            disabled={isSubmitted}
          />
        </Row>
        {selectedAnsQ_4 === OPTIONS[1].value && (
          <Row className="mb-3">
            <FormField
              label={"How many short?"}
              name={`ans_${Q4.id}`}
              register={register}
              size="12"
              error={reportError?.[`ans_${Q4.id}`]}
              disabled={isSubmitted}
            />
          </Row>
        )}
        <Row className="mb-2 align-items-center">
          <Col xs={""} className="fs-3 fw-medium">
            {Q5.subcat}
          </Col>
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q5.id}`}
            register={register}
            options={OPTIONS}
            size="auto"
            error={reportError?.[`questions_${Q5.id}`]}
            disabled={isSubmitted}
          />
        </Row>
        {selectedAnsQ_5 === OPTIONS[1].value && (
          <Row className="mb-3">
            <FormField
              label={"How many short?"}
              name={`ans_${Q5.id}`}
              register={register}
              size="12"
              error={reportError?.[`ans_${Q5.id}`]}
            />
          </Row>
        )}
        <Row className="mb-2 align-items-center">
          <Col xs={""} className="fs-3 fw-medium">
            {Q6.subcat}
          </Col>
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q6.id}`}
            register={register}
            options={OPTIONS}
            size="auto"
            error={reportError?.[`questions_${Q6.id}`]}
            disabled={isSubmitted}
          />
        </Row>
        {selectedAnsQ_6 === OPTIONS[1].value && (
          <Row className="mb-3">
            <FormField
              label={"How many short?"}
              name={`ans_${Q6.id}`}
              register={register}
              size="12"
              error={reportError?.[`ans_${Q6.id}`]}
              disabled={isSubmitted}
            />
          </Row>
        )}
      </Card.Body>
    </Fragment>
  );
};

export default Q_05;
