import React, { Fragment, useEffect, useState } from "react";
import QuestionHeading from "./QuestionHeading";
import { RIconWatch } from "../../../components/Icons";
import { Card, Row } from "react-bootstrap";
import { FormField, FormFieldCheck } from "../../../components/Form/FormField";
const OPTIONS = [
  { label: "No", value: "No" },
  { label: "Yes", value: "Yes" },
];
const Q_03 = ({
  dayQuestions,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  isSubmitted,
}) => {
  const [bgClass, setBgClass] = useState("");
  const Q1 = dayQuestions.find((item) => item.id === 32);
  const Q2 = dayQuestions.find((item) => item.id === 33);
  const Q3 = dayQuestions.find((item) => item.id === 34);
  const Q4 = dayQuestions.find((item) => item.id === 35);
  const selectedAns = watch(`questions_${Q1.id}`);
  useEffect(() => {
    if (selectedAns === OPTIONS[0].value) {
      setValue(`questions_${Q2.id}`, "");
      setValue(`questions_${Q3.id}`, "");
      setValue(`questions_${Q4.id}`, "");
    }
    if (selectedAns) {
      setBgClass(selectedAns === OPTIONS[0].value ? "success" : "danger");
    }
  }, [selectedAns]);
  return (
    <Fragment>
      <Card.Body className={`question-heading px-3 bg-${bgClass}-lt text-dark`}>
        <QuestionHeading
          icon={<RIconWatch className={`text-primary text-${bgClass}`} />}
          number="03"
          title={Q1.subcat}
        />
      </Card.Body>
      <Card.Body
        className={`px-3 ${
          selectedAns === OPTIONS[1].value ? "pt-2" : "py-2"
        } `}
      >
        <Row className="row-cards">
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q1.id}`}
            register={register}
            options={OPTIONS}
            size="12"
            error={reportError?.[`questions_${Q1.id}`]}
            disabled={isSubmitted}
          />
          {selectedAns === OPTIONS[1].value && (
            <Fragment>
              <FormField
                label={Q2.subcat}
                name={`questions_${Q2.id}`}
                register={register}
                size="12"
                error={reportError?.[`questions_${Q2.id}`]}
                disabled={isSubmitted}
              />
              <FormField
                label={Q3.subcat}
                name={`questions_${Q3.id}`}
                register={register}
                size="12"
                error={reportError?.[`questions_${Q3.id}`]}
                disabled={isSubmitted}
              />
              <FormField
                label={Q4.subcat}
                name={`questions_${Q4.id}`}
                register={register}
                size="12"
                as="textarea"
                rows="4"
                error={reportError?.[`questions_${Q4.id}`]}
                disabled={isSubmitted}
              />
            </Fragment>
          )}
        </Row>
      </Card.Body>
    </Fragment>
  );
};

export default Q_03;
