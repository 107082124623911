import React, { useState, useEffect } from "react";

const shift1QuestionsId = [2, 3, 6, 7, 8, 9];
const shift2QuestionsId = [11, 12, 15, 16, 17, 18];
const shift3QuestionsId = [20, 21, 24, 25, 26, 27];
const dayQuestionsId = [
  28, 29, 32, 36, 38, 39, 40, 41, 42, 43, 44, 46, 47, 48, 49, 50, 51, 52, 53,
  58, 59, 60, 61, 62, 63, 64, 68, 69,
]; //removed being validate 65,66,67

// const allQuestionsId = [
//   ...shift1QuestionsId,
//   ...shift2QuestionsId,
//   ...shift3QuestionsId,
//   ...dayQuestionsId,
// ];

const QuestionsProgress = ({ watch, examData }) => {
  const { number_of_shift = 3 } = examData || {}; // Ensure examData has a default value
  const [answeredCount, setAnsweredCount] = useState(0);
  const allQuestionsId = [
    ...shift1QuestionsId,
    ...(number_of_shift >= 2 ? shift2QuestionsId : []),
    ...(number_of_shift >= 3 ? shift3QuestionsId : []),
    ...dayQuestionsId, // Always include dayQuestionsId
  ];
  useEffect(() => {
    const subscription = watch((values) => {
      const answeredQuestions = allQuestionsId.filter(
        (id) =>
          values[`questions_${id}`] && values[`questions_${id}`].trim() !== ""
      );
      setAnsweredCount(answeredQuestions.length);
    });

    return () => subscription.unsubscribe(); // Cleanup on unmount
  }, [watch]);

  const totalQuestions = allQuestionsId.length;
  const progressPercentage = ((answeredCount / totalQuestions) * 100).toFixed(
    2
  );

  return (
    <div className="progress progress-sm q-progress mt-0 px-0">
      <div
        className="progress-bar bg-primary"
        style={{ width: `${progressPercentage}%` }}
        role="progressbar"
        aria-valuenow={progressPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

export default QuestionsProgress;
