import React, { Fragment, useEffect, useState } from "react";
import { PageBody } from "../../components/SiteWrapper";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import DateBox from "../../components/General/DateBox";
import { ExamList } from "../../components/Exam";
import { getExamStatus } from "../../api/ExamsAPI";
import NoDataFound from "../../components/General/NoDataFound";
import AnimatedComponent from "../../components/Animation/AnimatedComponent";

const CenterCoordinateExams = () => {
  const [inProgressExams, setInProgressExams] = useState([]);
  const [upcomingExams, setUpcomingExams] = useState([]);
  const fetchExams = async () => {
    const examData = await getExamStatus();
    const { completed, inProgress, upcoming } = examData.data;
    setUpcomingExams(upcoming);
    setInProgressExams(inProgress);
    // setUpcomingExams(completed);
  };
  useEffect(() => {
    fetchExams();
  }, []);
  return (
    <Fragment>
      <h2 className="px-3 py-2 bg-heading fs-2 mb-0">Exams</h2>
      <AnimatedComponent as={PageBody} className="mt-0">
        <Container>
          {/* In Progress Exams */}
          {inProgressExams &&
            upcomingExams &&
            inProgressExams.length === 0 &&
            upcomingExams.length === 0 && (
              <NoDataFound message="No exams found" />
            )}
          {inProgressExams && inProgressExams.length > 0 && (
            <ExamList
              title="In Progress Exams"
              exams={inProgressExams.map((exam) => ({
                title: exam.exam_name,
                subtitle: `${exam.Post.description} (${exam.Post.name})`,
                headerBg: "bg-warning-lt",
                rows: exam.examDays,
                footer: "View Report",
              }))}
            />
          )}
          {/* Upcoming Exams */}
          {upcomingExams && upcomingExams.length > 0 && (
            <ExamList
              title="Upcoming Exams"
              exams={upcomingExams.map((exam) => ({
                title: exam.exam_name,
                subtitle: `${exam.Post.description} (${exam.Post.name})`,
                headerBg: "bg-purple-lt",
                rows: exam.examDays,
                footer: null,
              }))}
            />
          )}
        </Container>
      </AnimatedComponent>
    </Fragment>
  );
};

export default CenterCoordinateExams;
