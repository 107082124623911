import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const getOne = async (id) => {
    return await axios
      .get(`${API_PREFIX}/submissions/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => Promise.reject(error?.response.data));
  };
export const update = async (id,payload) => {
  return await axios
    .post(`${API_PREFIX}/submissions/${id}`,payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
