import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rrbs: 0,
  posts: 0,
  users: 0,
  divisions: 0,
  centers: 0,
  exams: 0,
  shouldUpdate: false, // Flag to track if the sidebar needs to update
};

export const sidebarCounterSlice = createSlice({
  name: "sidebarCounter",
  initialState,
  reducers: {
    addsidebarCounter: (state, action) => {
      // Update the sidebar counts
      state.rrbs = action.payload.rrbs;
      state.posts = action.payload.posts;
      state.users = action.payload.users;
      state.divisions = action.payload.divisions;
      state.centers = action.payload.centers;
      state.exams = action.payload.exams;

      // Set the shouldUpdate flag to true to indicate an update
      state.shouldUpdate = true;
    },

    resetsidebarCounter: (state, action) => {
      // Reset all counts to 0
      state.rrbs = 0;
      state.posts = 0;
      state.users = 0;
      state.divisions = 0;
      state.centers = 0;
      state.exams = 0;

      // Set the shouldUpdate flag to false to indicate reset
      state.shouldUpdate = false;
    },

    // Optional: You can manually toggle the shouldUpdate flag if needed
    toggleShouldUpdate: (state) => {
      state.shouldUpdate = !state.shouldUpdate;
    },
  },
});

export const { addsidebarCounter, resetsidebarCounter, toggleShouldUpdate } =
  sidebarCounterSlice.actions;

export default sidebarCounterSlice.reducer;
