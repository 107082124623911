import * as Yup from "yup";
import { REQUIRED_FIELD, REQUIRED_NUMBER_FIELD, REQUIRED_NUMBER_WITH_ZERO_FIELD } from "../../common/validation";
const createDependentField = (dependencyKey) =>
  Yup.lazy((value, { parent }) => {
    return Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .required("This field is required!")
      .typeError("Invalid value!")
      .positive("Value must be positive!")
      .max(
        parent[dependencyKey],
        `Present count cannot exceed Scheduled Count!`
      );
  });
const createConditionalField = (dependencyKey, triggerValue) =>
  Yup.string()
    .nullable()
    .when(dependencyKey, {
      is: (value) => value === triggerValue, // Trigger validation when the dependency field matches the condition
      then: () => REQUIRED_FIELD,
      otherwise: () => Yup.string().notRequired(), // Optional when the condition is not met
    });
export const noValidationSchema = Yup.object();
export const reportSchema = Yup.object().shape({
  //shift-1
  questions_2: REQUIRED_NUMBER_FIELD,
  questions_3: createDependentField("questions_2"),

  questions_6: REQUIRED_NUMBER_WITH_ZERO_FIELD,
  questions_7: REQUIRED_NUMBER_WITH_ZERO_FIELD,
  questions_8: REQUIRED_NUMBER_WITH_ZERO_FIELD,
  questions_9: REQUIRED_NUMBER_WITH_ZERO_FIELD,

  //shift-2
  // questions_11: REQUIRED_NUMBER_FIELD,
  // questions_12: createDependentField("questions_11"),

  // questions_15: REQUIRED_NUMBER_FIELD,
  // questions_16: REQUIRED_NUMBER_FIELD,
  // questions_17: REQUIRED_NUMBER_FIELD,
  // questions_18: REQUIRED_NUMBER_FIELD,

  //shift-3
  // questions_20: REQUIRED_NUMBER_FIELD,
  // questions_21: createDependentField("questions_20"),

  // questions_24: REQUIRED_NUMBER_FIELD,
  // questions_25: REQUIRED_NUMBER_FIELD,
  // questions_26: REQUIRED_NUMBER_FIELD,
  // questions_27: REQUIRED_NUMBER_FIELD,

  questions_28: REQUIRED_FIELD,
  ans_28: createConditionalField("questions_28", "No"),

  questions_29: REQUIRED_FIELD,
  questions_30: createConditionalField("questions_29", "Not Conducted Smoothly"),
  questions_31: createConditionalField("questions_29", "Not Conducted Smoothly"),

  questions_32: REQUIRED_FIELD,
  questions_33: createConditionalField("questions_32", "Yes"),
  questions_34: createConditionalField("questions_32", "Yes"),
  questions_35: createConditionalField("questions_32", "Yes"),

  questions_36: REQUIRED_FIELD,

  questions_38: REQUIRED_FIELD,
  ans_38: createConditionalField("questions_38", "Absent"),
  questions_39: REQUIRED_FIELD,
  ans_39: createConditionalField("questions_39", "Absent"),
  questions_40: REQUIRED_FIELD,
  ans_40: createConditionalField("questions_40", "Absent"),
  questions_41: REQUIRED_FIELD,
  ans_41: createConditionalField("questions_41", "Absent"),
  questions_42: REQUIRED_FIELD,
  ans_42: createConditionalField("questions_42", "Absent"),

  questions_43: REQUIRED_FIELD,

  questions_44: REQUIRED_FIELD,

  questions_46: REQUIRED_FIELD,
  ans_46: createConditionalField("questions_46", "Absent"),
  questions_47: REQUIRED_FIELD,
  ans_47: createConditionalField("questions_47", "Absent"),
  questions_48: REQUIRED_FIELD,
  ans_48: createConditionalField("questions_48", "Absent"),
  questions_49: REQUIRED_FIELD,
  ans_49: createConditionalField("questions_49", "Absent"),
  questions_50: REQUIRED_FIELD,
  ans_50: createConditionalField("questions_50", "Absent"),
  questions_51: REQUIRED_FIELD,
  ans_51: createConditionalField("questions_51", "Absent"),

  questions_52: REQUIRED_FIELD,

  questions_53: REQUIRED_FIELD,
  questions_54: createConditionalField("questions_53", "Yes"),
  questions_55: createConditionalField("questions_53", "Yes"),
  questions_56: createConditionalField("questions_53", "Yes"),

  questions_58: REQUIRED_FIELD,
  questions_59: REQUIRED_FIELD,
  questions_60: REQUIRED_FIELD,
  questions_61: REQUIRED_FIELD,
  questions_62: REQUIRED_FIELD,

  questions_63: REQUIRED_FIELD,

  questions_64: REQUIRED_FIELD,

  // questions_65: REQUIRED_FIELD,

  // questions_66: REQUIRED_FIELD,

  // questions_67: REQUIRED_FIELD,

  questions_68: REQUIRED_FIELD,

  questions_69: REQUIRED_FIELD,
});
