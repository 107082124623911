import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import RoleProtectedRoute from "../components/Authentication/RoleProtectedRoute";
import { useSelector } from "react-redux";
import { ROLES } from "../common/constants";
import CenterCoordinateExams from "../pages/Exams/CenterCoordinateExams";
import CenterCoordinateExamsCompleted from "../pages/Exams/CenterCoordinateExamsCompleted";
import Reports from "../pages/Exams/Reports";

const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Exams = lazy(() => import("../pages/Exams"));
const AddEditExam = lazy(() => import("../pages/Exams/EditExam"));
const ExamReport = lazy(() => import("../pages/ExamReport/ExamReport"));

const Divisions = lazy(() => import("../pages/Setup/Divisions"));
const Centers = lazy(() => import("../pages/Setup/Centers"));
const Users = lazy(() => import("../pages/Setup/Users"));
const Posts = lazy(() => import("../pages/Setup/Posts"));
const Rrb = lazy(() => import("../pages/Setup/Rrb"));

const unauthorizedRoutes = [
  {
    path: "/",
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace={true} />,
  },
];

// const authorizedRoutes = (auth) => {
//   return [
//     {
//       path: "/",
//       element: <ProtectedRoute redirectPath={"/login"} auth={auth} />,
//       children: [
//         {
//           index: true,
//           element: <Dashboard />,
//         },
//         {
//           path: "dashboard",
//           element: <Dashboard />,
//         },
//         {
//           path: "exams",
//           element: <Exams />,
//         },
//         {
//           path: "exams/add",
//           element: <AddEditExam />,
//         },
//         {
//           path: "exams/edit/:id",
//           element: <AddEditExam />,
//         },
//         // {
//         //     path: 'setup',
//         //     element: <Dashboard />,
//         // },
//         {
//           path: "divisions",
//           element: <Divisions />,
//         },
//         {
//           path: "centers",
//           element: <Centers />,
//         },
//         {
//           path: "users",
//           element: <Users />,
//         },
//         {
//           path: "posts",
//           element: <Posts />,
//         },
//         {
//           path: "logout",
//           element: <Logout />,
//         },
//       ],
//     },
//     {
//       path: "*",
//       element: <Navigate to="/" replace={true} />,
//     },
//   ];
// };
const authorizedRoutes = (auth, role) => {
  const userRole = auth?.role; // Extract user role from auth object

  return [
    {
      path: "/",
      element: <ProtectedRoute redirectPath={"/login"} auth={auth} />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "exams",
          element: (
            <RoleProtectedRoute
              allowedRoles={["admin", "center-coordinator"]}
              userRole={role.slug}
            >
              {role.slug === ROLES.CENTER_COORDINATOR ? (
                <CenterCoordinateExams />
              ) : (
                <Exams />
              )}
            </RoleProtectedRoute>
          ),
        },
        {
          path: "/completed-exams",
          element: (
            <RoleProtectedRoute
              allowedRoles={["center-coordinator"]}
              userRole={role.slug}
            >
              <CenterCoordinateExamsCompleted />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "/reports/:id",
          element: (
            <RoleProtectedRoute
              allowedRoles={["center-coordinator"]}
              userRole={role.slug}
            >
              <Reports />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "exams/add",
          element: (
            <RoleProtectedRoute allowedRoles={["admin"]} userRole={role.slug}>
              <AddEditExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "exams/edit/:id",
          element: (
            <RoleProtectedRoute allowedRoles={["admin"]} userRole={role.slug}>
              <AddEditExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "exams/reports/:id",
          element: (
            <RoleProtectedRoute allowedRoles={["admin", "region-coordinator", "additional-region-coordinator"]} userRole={role.slug}>
              <ExamReport />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "divisions",
          element: (
            <RoleProtectedRoute allowedRoles={["admin"]} userRole={role.slug}>
              <Divisions />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "centers",
          element: (
            <RoleProtectedRoute allowedRoles={["admin"]} userRole={role.slug}>
              <Centers />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "users",
          element: (
            <RoleProtectedRoute
              allowedRoles={["admin", "super-admin"]}
              userRole={role.slug}
            >
              <Users />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "posts",
          element: (
            <RoleProtectedRoute
              allowedRoles={["super-admin"]}
              userRole={role.slug}
            >
              <Posts />
            </RoleProtectedRoute>
          ),
        },
        {
          path: "recruitment_boards",
          element: (
            <RoleProtectedRoute
              allowedRoles={["super-admin"]}
              userRole={role.slug}
            >
              <Rrb />
            </RoleProtectedRoute>
          ),
        },

        {
          path: "logout",
          element: <Logout />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" replace={true} />,
    },
  ];
};
export const Routes = (auth) => {
  const authSelector = useSelector((state) => state.rrb.authUserReducer);
  return auth ? authorizedRoutes(auth, authSelector?.role) : unauthorizedRoutes;
};
