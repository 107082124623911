import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import CollapsibleCard from "../../../components/Exam/reports";
import { Card, Row } from "react-bootstrap";
import { FormField } from "../../../components/Form/FormField";
import moment from "moment";

const ShiftQuestions = ({
  examData,
  shiftData,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  statuses,
}) => {
  // Define field groups for total, present, and percentage
  const fieldGroups = useMemo(
    () => [
      {
        total: "questions_2",
        present: "questions_3",
        percentage: "questions_4",
      },
      {
        total: "questions_11",
        present: "questions_12",
        percentage: "questions_13",
      },
      {
        total: "questions_20",
        present: "questions_21",
        percentage: "questions_22",
      },
    ],
    []
  );

  // Memoized calculation function
  const calculateAttendance = useCallback(
    (total, present, percentageField) => {
      const totalValue = parseFloat(total);
      const presentValue = parseFloat(present);

      if (!isNaN(totalValue) && !isNaN(presentValue) && totalValue > 0) {
        // Calculate percentage
        const calculatedPercentage = (
          (presentValue / totalValue) *
          100
        ).toFixed(2);
        setValue(
          percentageField,
          calculatedPercentage <= 100 ? calculatedPercentage : ""
        );
      } else {
        setValue(percentageField, ""); // Clear the field if invalid
      }
    },
    [setValue]
  );
  // Watch all total and present fields dynamically
  const watchedFields = watch(
    fieldGroups.flatMap(({ total, present }) => [total, present])
  );

  // Update attendance percentages when watched fields change
  useEffect(() => {
    fieldGroups.forEach(({ total, present, percentage }, index) => {
      const totalValue = watchedFields[index * 2]; // Even indices: total
      const presentValue = watchedFields[index * 2 + 1]; // Odd indices: present
      calculateAttendance(totalValue, presentValue, percentage);
      // Trigger validation for the present field if it's not blank
      if (presentValue && presentValue !== "") {
        trigger(present); // Revalidate the present field
      }
    });
  }, [
    JSON.stringify(watchedFields),
    calculateAttendance,
    fieldGroups,
    trigger,
  ]);
  const getFormattedTime = (title) => {
    const shiftNumber = title.split("-")[1];
    const startTime = examData?.shifts?.[`shift_${shiftNumber}_start_time`];
    if (!startTime || !moment(startTime).isValid()) {
      return ""; // Return empty string if startTime is invalid
    }
    const formattedStartTime = moment(startTime).format("hh:mm A");
    return formattedStartTime;
  };
  const getStatusKey = (name) => {
    // Convert "Shift-1" to "shift1", "Shift-2" to "shift2", and so on
    return name.toLowerCase().replace("-", "");
  };
  return (
    <Fragment>
      {shiftData?.map((shift, idx) => {
        const { name, heading, isIncluded } = shift;
        if (!isIncluded) return;
        const statusKey = getStatusKey(shift.name); // Get the corresponding key for statuses
        const badgeStatus = statuses[statusKey];
        return (
          <CollapsibleCard
            key={idx}
            title={name}
            badgeStatus={badgeStatus}
            showBadge={true}
            time={getFormattedTime(name)}
            showTime={true}
          >
            {heading.map((item, itemIdx) => {
              const { subcat, questions } = item;
              return (
                <Fragment key={itemIdx}>
                  <h3 className="question-heading border-bottom mb-0">
                    {subcat}
                  </h3>
                  <Card.Body className={`border-bottom px-3`}>
                    <Row className="row-cards">
                      {questions.map((question, qIdx) => {
                        return (
                          <FormField
                            key={qIdx}
                            label={question.subcat}
                            name={`questions_${question.id}`}
                            type="number"
                            error={reportError?.[`questions_${question.id}`]}
                            register={register}
                            size="12"
                            required
                            disabled={
                              examData.submitted ||
                              [4, 13, 22].includes(question.id)
                            }
                          />
                        );
                      })}
                    </Row>
                  </Card.Body>
                </Fragment>
              );
            })}
          </CollapsibleCard>
        );
      })}
    </Fragment>
  );
};

export default ShiftQuestions;
