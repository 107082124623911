export const ROLES = {
  SUPERADMIN: "super-admin",
  CENTER_COORDINATOR: "center-coordinator",
};
export const PAGE_LENGTH = [10, 25, 50, 100];
export const DEFAULT_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: "Are you sure?",
  description:
    "Do you really want to delete this record? This operation cannot be undone later.",
  actionBtnText: "Delete",
  action: "DELETE",
};
export const DEFAULT_CRED_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: "Are you sure?",
  description:
    "Do you wish to send login details (username/password) to all the center coordinators for this exam?",
  actionBtnText: "SEND",
  action: "SEND",
};
export const DEFAULT_CONFIRM_OFFCANVAS = {
  show: false,
  isLoading: false,
  title: "Confirmation",
  description:
    "Do you really want to delete this record? This operation cannot be undone later.",
  actionBtnText: "Submit",
  action: "submit",
};
export const CITIES = [
  { label: "ADI", value: "ahemdabad" },
  { label: "MSH", value: "mehsana" },
  { label: "GNC", value: "gandhinagar" },
  { label: "RJT", value: "rajkot" },
  { label: "JAM", value: "jamnagar" },
  { label: "BRC", value: "metoda" },
  // { label: "Vadodara", value: "vadodara" },
  { label: "BVC", value: "gotri" },
];
export const ROLE_LIST = [
  { label: "Super Admin", value: "super-admin" },
  //   { label: "Region Coordinator", value: "region-coordinator" },
  { label: "Center Coordinator", value: "center-coordinator" },
];
export const EXAM_STATUS = [{ label: "Upcoming", value: "upcoming" }];
export const EXAM_DURATION = [
  { label: "30", value: 30 },
  { label: "45", value: 45 },
  { label: "60", value: 60 },
  { label: "75", value: 75 },
  { label: "90", value: 90 },
];
