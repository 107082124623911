import React, { Fragment, useEffect, useState } from "react";
import QuestionHeading from "./QuestionHeading";
import { RIconPolice } from "../../../components/Icons";
import { Card, Row } from "react-bootstrap";
import { FormField, FormFieldCheck } from "../../../components/Form/FormField";

const OPTIONS = [
  { label: "No", value: "No" },
  { label: "Yes", value: "Yes" },
];
const Q_13 = ({
  dayQuestions,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  isSubmitted
}) => {
  const [bgClass, setBgClass] = useState("");
  const Q1 = dayQuestions.find((item) => item.id === 64);

  const selectedAns = watch(`questions_${Q1.id}`);
  useEffect(() => {
    if (selectedAns === OPTIONS[0].value) {
      setValue(`ans_${Q1.id}`, "");
    }
    if(selectedAns){
      setBgClass(selectedAns==="No"?"success":"danger");
    }
  }, [selectedAns]);
  return (
    <Fragment>
      <Card.Body className={`question-heading px-3 bg-${bgClass}-lt text-dark`}>
        <QuestionHeading icon={<RIconPolice className={`text-primary text-${bgClass}`} />} number="13" title={Q1.subcat} />
      </Card.Body>
      <Card.Body
        className={`px-3 ${
          selectedAns === OPTIONS[1].value ? "pt-2" : "py-2"
        } `}
      >
        <Row className="row-cards">
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q1.id}`}
            register={register}
            options={OPTIONS}
            size="12"
            error={reportError?.[`questions_${Q1.id}`]}
            disabled={isSubmitted}
          />
          {selectedAns === OPTIONS[1].value && (
            <FormField
              label={"Brief Details"}
              name={`ans_${Q1.id}`}
              register={register}
              size="12"
              as="textarea"
              rows="4"
              disabled={isSubmitted}
            />
          )}
        </Row>
      </Card.Body>
    </Fragment>
  );
};

export default Q_13;
