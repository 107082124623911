import { io } from "socket.io-client";

let socket;

export const getSocket = () => {
  if (!socket) {
    const SERVER_URL =
      process.env.REACT_APP_SOCKET_URL || "http://localhost:2000/";
    socket = io(SERVER_URL, { transports: ["websocket", "polling"] });
  }
  return socket;
};
