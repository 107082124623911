import React, { Fragment, useEffect, useState } from "react";
import QuestionHeading from "./QuestionHeading";
import { RIconPC } from "../../../components/Icons";
import { Card, Row } from "react-bootstrap";
import { FormField, FormFieldCheck } from "../../../components/Form/FormField";
const OPTIONS = [
  { label: "Conducted Smoothly", value: "Conducted Smoothly" },
  { label: "Not Conducted Smoothly", value: "Not Conducted Smoothly" },
];
const Q_02 = ({
  dayQuestions,
  register,
  watch,
  setValue,
  reportError,
  trigger,
  isSubmitted
}) => {
  const [bgClass, setBgClass] = useState("");
  const Q1 = dayQuestions.find((item) => item.id === 29);
  const Q2 = dayQuestions.find((item) => item.id === 30);
  const Q3 = dayQuestions.find((item) => item.id === 31);
  const selectedAns = watch(`questions_${Q1.id}`);
  useEffect(() => {
    if (selectedAns === OPTIONS[0].value) {
      setValue(`questions_${Q2.id}`, "");
      setValue(`questions_${Q3.id}`, "");
    }
    if(selectedAns){
      setBgClass(selectedAns===OPTIONS[0].value?"success":"danger");
    }
  }, [selectedAns]);
  return (
    <Fragment>
      <Card.Body className={`question-heading px-3 bg-${bgClass}-lt text-dark`}>
        <QuestionHeading icon={<RIconPC className={`text-primary text-${bgClass}`} />} number="02" title={Q1.subcat} />
      </Card.Body>
      <Card.Body
        className={`px-3 ${
          selectedAns === OPTIONS[1].value ? "pt-2" : "py-2"
        } `}
      >
        <Row className="row-cards">
          <FormFieldCheck
            isLabel={false}
            name={`questions_${Q1.id}`}
            register={register}
            options={OPTIONS}
            inline={false}
            size="12"
            error={reportError?.[`questions_${Q1.id}`]}
            disabled={isSubmitted}
          />
          {selectedAns === OPTIONS[1].value && (
            <Fragment>
              <FormField
                label={Q2.subcat}
                name={`questions_${Q2.id}`}
                register={register}
                size="12"
                error={reportError?.[`questions_${Q2.id}`]}
                disabled={isSubmitted}
              />
              <FormField
                label={Q3.subcat}
                name={`questions_${Q3.id}`}
                register={register}
                size="12"
                as="textarea"
                rows="4"
                error={reportError?.[`questions_${Q3.id}`]}
                disabled={isSubmitted}
              />
            </Fragment>
          )}
        </Row>
      </Card.Body>
    </Fragment>
  );
};

export default Q_02;
