import React, { useState } from "react";
import { Badge, Card, Col, Collapse, Row } from "react-bootstrap";
import { IconChevronUp, IconChevronDown } from "@tabler/icons-react";
import DateBox from "../General/DateBox";

const CollapsibleCard = ({
  title,
  badgeStatus,
  time,
  showBadge = false,
  showTime = false,
  defaultOpen = false,
  children,
}) => {
  const [open, setOpen] = useState(defaultOpen); // State to manage collapse

  // Determine badge color based on badgeStatus
  const getBadgeColor = () => {
    switch (badgeStatus) {
      case "success":
        return "success";
      case "warning":
        return "warning";
      default:
        return ""; // Default to muted
    }
  };
  return (
    <Card className="card-sm mb-3">
      <Card.Header
        className={`d-flex justify-content-between align-items-center cursor-pointer px-3 ${
          !open ? "border-bottom-0" : ""
        }`}
        onClick={() => setOpen(!open)}
      >
        <div className="d-flex align-items-center">
          {showBadge && (
            <Badge bg={getBadgeColor()} className="badge-empty me-2 " />
          )}
          <h5 className="mb-0 fs-18 fw-semibold lh-normal">{title}</h5>
        </div>
        <div className="d-flex align-items-center">
          {/* Conditionally show time */}
          {showTime && <span className=" text-muted fs-3">{time}</span>}
          {/* Chevron Icon */}
          {open ? (
            <IconChevronUp className="icon ms-3" color="#999999" />
          ) : (
            <IconChevronDown className="icon ms-3" color="#999999" />
          )}
        </div>
      </Card.Header>
      <Collapse in={open}>
        <div>{children}</div>
      </Collapse>
    </Card>
  );
};

export const ExamDetails = ({ examData }) => {
  const statusClassMap = {
    "In Progress": "warning-lt",
    Completed: "success-lt",
    Upcoming: "purple-lt",
  };

  const statusClass = statusClassMap[examData.status] || "";

  return (
    <div>
      {/* Header Section */}
      <div
        className={`px-3 py-2 border-bottom border-card card-header d-block rounded-0 bg-${statusClass}`}
      >
        <h3 className="mb-1 text-dark fw-medium fs-18">{examData.name}</h3>
        <span className="d-block text-secondary fs-3 fw-normal">
          {`${examData.post.description} (${examData.post.name})`}
        </span>
      </div>

      {/* Body Section */}
      <div className="p-3">
        <Row className="align-items-center">
          {/* Date Section */}
          <Col className="col-auto">
            <DateBox date={examData.day.date} />
          </Col>

          {/* Center Details */}
          <Col>
            <h3 className="fw-medium fs-18 mb-1">
              {`${examData.day.center.center_code}`}
              <span className="mx-2">|</span>
              {`${examData.day.center.center_name}`}
            </h3>
            <span className="text-secondary fs-3">
              {`${examData.day.center.city}, ${examData.day.center.division}`}
            </span>
          </Col>

          {/* Status Badge */}
          <Col className="col-auto">
            <Badge bg={statusClass} className="border">
              {examData.status}
            </Badge>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CollapsibleCard;
